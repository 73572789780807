import React from "react";
import { connect } from "react-redux";
import "../../css/Commons.css";
import "./SubMenu.css";

function SubMenu({ title, newLabel }) {

  return (
    <div className="submenu-container">
    <div className="title-container">
      <h1 className="orange">{title}</h1>
    </div>

    <div className="options-container">
      <div className="option-title-container float-left">
        <span className="option-title blue">
          Ver
        </span>
      </div>
      <div className="arrow-container float-left">
        <img src="/icons/submenu/arrow-up.svg" />
      </div>
      
    </div>

    <div className="widget-container">
      <div className="new-container float-left">
        <p className="green widget-label">
          <img src="/icons/submenu/new-icon.svg" alt={newLabel} />&nbsp;{newLabel}
        </p>
      </div>

      <div className="vertical-separator float-left"/>

      <div className="dashboard-container float-left">
        <p className="green widget-label">
          <img src="/icons/submenu/dashboard-icon.svg" alt="Dashboard" />&nbsp;Dashboard
        </p>
      </div>
    </div>

    <div className="total-container">
      <span className="total-label blue">
      Total: 0 {title}
      </span>
    </div>
    </div>
  );
}

export default connect()(SubMenu);