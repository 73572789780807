import React, { useMemo } from "react";
import { connect } from "react-redux";
import SubMenu from "../components/submenu/SubMenu";
import "../css/Commons.css";
import "./Proyectos.css";
import { CheckTable } from "../components/checkTable/CheckTable";

function Proyectos({}) {

  const columns = useMemo(
    () => [
      {
        Header: 'Nombre del Proyecto',
        accessor: 'proyecto',
      },
      {
        Header: 'Responsable',
        accessor: 'responsable',
      },
      {
        Header: 'Fecha de Inicio',
        accessor: 'departamento',
      },
      {
        Header: 'Estado',
        accessor: 'estado',
      },
      {
        Header: 'Acciones',
        accessor: 'acciones',
      },
    ], []
  );

  const data = useMemo(() => {
    return [];
  }, []);

  return (
    <div className="page-menu-container">
    <SubMenu 
      title="Proyectos"
      newLabel="Nuevo Proyecto"
    />

    <div className="proyectos-form">
      <div className="icons-container">
        <div className="float-left icon">
          <img src="icons/Download.svg"/>
        </div>
        <div className="float-left icon">
          <img src="icons/Imprimir.svg"/>
        </div>
      </div>
      <br />
      <CheckTable 
        columns={columns}
        data={data}
      /> 
      <br />
      <div className="center">
      <p>
        No hay proyectos por el momento
      </p>
      </div>
    </div>

    </div>
  );

}

export default connect()(Proyectos);
